import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { ApiService } from '../../../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: false } }
  ]
})
export class RegisterComponent implements OnInit {

  name:any = '';
  company:any = '';
  email:any = '';
  password:any = '';
  terms:any = '';
  btntxt:any = 'Create new account';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  userid:any;
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.userid=sessionStorage.getItem("userid");

    if(this.userid=='' || this.userid==undefined) {
      
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  signup() {
      if(this.name=='' || this.company=='' || this.email=='' || this.password=='') {
          this.message='Please fill all the details';
      } else {
          if(this.terms=='') {
              this.message='Please read and accept our terms and conditions';
          } else {
              this.btntxt="Creating account..."
              this.itemData = {
                "name":this.name,
                "company":this.company,
                "email":this.email,
                "password":this.password
              }
              this.apiService.post('company', this.itemData).then((res: any) => {
                  this.btndisabled=1;
                  if(res.status==204) {
                    this.btntxt='Create new account';
                    this.btndisabled=0;
                  } else {
                    this.btntxt='Registration Successful'
                    this.name='';
                    this.company='';
                    this.email='';
                    this.password='';
                    this.terms='';
                  } 
                  this.message=res.msg;            
              });
          }
      }
  }

}
