import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';
  parent:any = '';
  description:any='';
  opbal:any = 0;
  opbaldate:any='';
  
  btntxt:any = 'Add Ledger';

  uname:any='';
  uparent:any = '';
  udescription:any='';
  uopbal:any = 0;
  uopbaldate:any='';
  
  ubtntxt:any = 'Edit Ledger';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  eparent:any = 0;
  uename:any = 0;
  ueparent:any = 0;
  

  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  ledgergrouplist:any='';
  ledgerlist:any='';
  constructor(private apiService: ApiService) { 
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getLedgergroups();
    this.getLedgers();
  }

  ngOnInit(): void {
  }

  getLedgergroups() {
      this.itemData = {
        "cid":this.cid
      }
      this.apiService.post('ledger-groups', this.itemData).then((res: any) => {
          this.ledgergrouplist=res.data;                          
      });
  }

  getLedgers() {
      this.itemData = {
        "cid":this.cid
      }
      this.apiService.post('ledgers', this.itemData).then((res: any) => {
          this.ledgerlist=res.data;                          
      });
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.getSingle(id);
  } 

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchcategory(searchval) {
    this.itemData = {
        "id":this.cid,
        search:searchval
      }
      this.apiService.post('search-categories', this.itemData).then((res: any) => {
          this.catlist=res.data;                          
      });
  }

  getSingle(id) {
      this.itemData = {
        "cid":this.cid,
        "id":id
      }
      this.apiService.post('get-ledger', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.udescription=res.data[0].description;  
          this.uparent=res.data[0].ledgergroupid;
          this.uopbal=res.data[0].op_bal;
          this.uopbaldate=res.data[0].op_bal_date;                        
      });
  }

  removeLedger(id) {
    if(confirm("Are you sure you want to delete this Ledger?")) {
        this.itemData = {
          "cid":this.cid,
          "id":id
        }
        this.apiService.post('remove-ledger', this.itemData).then((res: any) => {
              this.ledgerlist=res.data;                        
        });
    }
  }

  addcategory() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.parent=='') {
        this.eparent=1;
        this.vcount=1;
      }
      if(this.opbal=='' || isNaN(this.opbal)) {
        this.opbal=0;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "parent":this.parent,
            "description":this.description,
            "opbal":this.opbal,
            "opbaldate":this.opbaldate.split('T')[0]
          }
          this.apiService.post('add-ledger', this.itemData).then((res: any) => {
            this.btntxt="Adding Ledger..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Ledger';
                this.btndisabled=0;
                this.ledgerlist=res.data; 
                this.name='';
                this.parent='';
                this.description='';
                this.opbal='';
                this.opbaldate='';
                this.onTab(1);
              }                          
          });
      }
  }

  editledger() {
    this.uename=0;
      this.vcount=0;
      if(this.uname=='') {
        this.uename=1;
        this.vcount=1;
      }
      if(this.uparent=='') {
        this.ueparent=1;
        this.vcount=1;
      }
      if(this.uopbal=='' || isNaN(this.uopbal)) {
        this.uopbal=0;
      }
    if(this.vcount==0) {
        this.itemData = {
          "cid":this.cid,
          "name":this.uname,
          "parent":this.uparent,
          "description":this.udescription,
          "opbal":this.uopbal,
          "opbaldate":this.uopbaldate.split('T')[0],
          "id":this.currentid
        }
        this.apiService.post('edit-ledger', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Ledger..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Ledger';
              this.btndisabled=0;
              this.ledgerlist=res.data; 
              this.uname='';
              this.udescription='';
              this.uparent='';
              this.uopbal='0';
              this.uopbaldate='';
              this.onTab(1);
            }                          
        });
    }
  }

}
