import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-hr-dashboard',
  templateUrl: './hr-dashboard.component.html',
  styleUrls: ['./hr-dashboard.component.scss']
})
export class HrDashboardComponent implements OnInit {

  name:any;
  cid:any;
  productcount:any = 0;
  customercount:any = 0;
  quotecount:any = 0;
  vendorcount:any = 0;
  itemData:any;
  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute) { 
    this.name = sessionStorage.getItem("name");
    this.cid = sessionStorage.getItem("cid");
    this.getCounter();
  }

  ngOnInit(): void {
  }

  getCounter() {
    this.itemData = {
      "cid":this.cid
    }
    this.apiService.post('counters', this.itemData).then((res: any) => {
      this.productcount=res.data[0].totalproducts;
      this.customercount=res.data[0].totalcustomers;
      this.quotecount=res.data[0].totalquotes;
      this.vendorcount=res.data[0].totalvendors;
    });
  }

}
