import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SessionService } from '../../../../session.service';

@Component({
  selector: 'app-balancesheet',
  templateUrl: './balancesheet.component.html',
  styleUrls: ['./balancesheet.component.scss']
})
export class BalancesheetComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  entrytab:any = 1;

  btntxt:any = 'Add Entry';
  ubtntxt:any = 'Edit Entry';

  voucherno:any = '';
  tdate:any ='';
  fdate:any = '';
  etdate:any = 0;

  itemData:any;
  btndisabled:any = 0;
  message:any;

  vcount:any = 0;

  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  fromledgerlist:any='';
  toledgerlist:any = '';

  transactions:any = '';
  debitledger:any;
  creditledger:any;

  entryForm: FormGroup;

  total:any = 0;
  remarks:any = '';

  pageno:any = 1;
  total_pages:any = 1;
  numbers:any ;

  credittotal:any = 0;
  debittotal:any = 0;
  fy:any = '';

  constructor(private apiService: ApiService, private formBuilder: FormBuilder,private session: SessionService,) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");

    this.fy = sessionStorage.getItem("fy");

    this.session.myFy$.subscribe((data) => {
        this.fy = data;
        this.getTransactions();
        sessionStorage.setItem("fy", data);
        if(this.fy=='' || this.fy==undefined) {
          this.fy=new Date().getFullYear();
        }
        console.log("hel"+this.fy)
    });

    this.getTransactions();
  }

  ngOnInit(): void {

  }




  getTransactions() {
      this.itemData = {
        "cid":this.cid,
        "fy":this.fy,
        "search":this.search,
      }
      this.apiService.post('balancesheet', this.itemData).then((res: any) => {
          this.transactions=res.data;
          this.findsum(res.data);
      });
  }

  searchTransactions() {
    this.itemData = {
      "cid":this.cid,
      "fy":this.fy,
      "search":this.search
    }
    this.apiService.post('balancesheet', this.itemData).then((res: any) => {
        this.transactions=res.data;
        this.findsum(res.data);
    });
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  findsum(data){
    this.credittotal=0;
    this.debittotal=0;
    for(let j=0;j<data.length;j++){
         this.credittotal+= data[j].credit;
         this.debittotal+= data[j].debit;
    }
  }


}
