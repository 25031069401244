import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  countryid:any = '';
  countryname:any = '';
  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = 0;
  cost:any = 0;

  btntxt:any = 'Add Country';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  countries:any;
  statuslist:any;
  projectlist:any;
  pid:any;

  estartdate:any = 0;
  eenddate:any = 0;
  vcount:any = 0;
  ename:any = '';
  ecode:any = '';
  eclient:any = 0;
  estatus:any = 0;

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getCountry();
  }

  ngOnInit(): void {
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getCountry() {
      this.itemData = {

      }
      this.apiService.post('countries', this.itemData).then((res: any) => {
          this.countries=res.data;
      });
  }

  upload() {
    this.vcount=0;
    this.ecode='';
    this.ename='';

    if(this.countryname=='' || this.countryname==undefined) {
      this.vcount=1;
      this.ename='Please enter Country Name';
    }
    if(this.countryname.length>100) {
      this.vcount=1;
      this.ename='Max characters allowed is 100';
    }
    if(this.countryid=='' || this.countryid==undefined) {
      this.vcount=1;
      this.ecode='Please enter Country ID';
    }
    if(this.countryid.length>2) {
      this.vcount=1;
      this.ecode='Max characters allowed is 2';
    }
    if(this.vcount==0) {
      this.itemData = {
        "countryname":this.countryname,
        "countryid":this.countryid,
      }
      this.apiService.post('country', this.itemData).then((res: any) => {
          this.btntxt="Saving Country..."
          this.btndisabled=1;
          if(res.status==200) {
            this.countryid='';
            this.countryname='';
            this.btndisabled=0;
            this.getCountry();
            this.btntxt="Add Country";
            this.onTab(1);
          }
      });
    }
  }

}
