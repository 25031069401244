import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  dayname:any = '';
  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  countryid:any = '';
  countryname:any = '';
  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = 0;
  cost:any = 0;

  btntxt:any = 'Add Calendar';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  countries:any;
  statuslist:any;
  projectlist:any;
  pid:any;

  estartdate:any = 0;
  eenddate:any = 0;
  vcount:any = 0;
  ename:any = '';
  ecode:any = '';
  eclient:any = 0;
  estatus:any = 0;

  dutystart:any = '';
  dutyhours:any = '';

  edayname:any = '';
  edutystart:any = '';
  edutyhours:any = '';

  calendardata:any = '';

  cmonth:any = '';
  corigmonth:any = '';
  cyear:any = '';
  cday:any = '';
  firstday:any = 1;

  sidedate:any = '';
  sidedayname:any = '';
  sideyear:any='';
  sidemonth:any='';
  sidedutystart:any = '';
  sidedutyhours:any = '';
  sideid:any = '';
  sidedayno:any = '';
  sidemessage:any = '';
  esidemsg:any = '';
  myeditdate:any = '';




  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) { 

    this.getCalendar();
    this.firstday = this.getFirstDayOfMonth(this.cyear, this.cmonth);
    console.log(this.firstday);
  }

  counter(i: number) {
      return new Array(i);
  }

  getFirstDayOfMonth(year, month) {
    console.log(year + "," + month);
    month=month-1;
    console.log(new Date(year, month, 1));
    return new Date(year, month, 1).getDay();
  }



  ngOnInit(): void {
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  async getCalendar() {
    var d = new Date(),
    month = d.getMonth(),
    year = d.getFullYear(),
    day = d.getDate();

    this.cmonth = month+1;
    this.corigmonth = month+1;
    this.cyear = year;
    this.cday=day

    this.itemData = {
      "month":month+1,
      "year":year,
    }
    await this.apiService.post('view-calendar', this.itemData).then((res: any) => {
        this.calendardata=res.data;
    });

  }

  ifAdded(i:any,month:any,year:any) {
    if(i<10) {
      i="0" + i;
    }
    if(month<10) {
      month="0" + month;
    }
    const newdate=year % 100 + "-" + month + "-" + i;
    var found = false;
    for(var j = 0; j < this.calendardata.length; j++) {

        if (this.calendardata[j].cdate == newdate) {
          found = true;
          return this.calendardata[j].duty_start;
          break;
        } else {
        }
    }
    if(found==false) {
      return false;
    }
  }

  editDetails(i:any,month:any,year:any) {
    this.sidedutystart='';
    this.sidedutyhours='';
    this.sideid='';
    if(i<10) {
      i="0" + i;
    }
    console.log(i);
    if(month<10) {
      month="0" + month;
    }
    const newdate=year % 100 + "-" + month + "-" + i;
    this.sidedate = i;
    this.sideyear = year;
    this.sidemonth = month;

    var found = false;
    for(var j = 0; j < this.calendardata.length; j++) {

        if (this.calendardata[j].cdate == newdate) {
          found = true;
          this.sidedutystart = this.calendardata[j].duty_start;
          this.sidedutyhours = this.calendardata[j].duty_hours;
          this.sideid = this.calendardata[j].id;
          this.sidedayno = this.calendardata[j].dayno;
          break;
        } 
    }
    if(found==false) {
      this.sidedutystart='';
      this.sidedutyhours='';
      this.sideid='';
      this.sidedayno='';
      const mynewdate=year + "-" + month + "-" + i;
      
      var d = new Date(mynewdate);
      this.myeditdate = d;
      console.log(d.getDay());
      if(d.getDay()<6) {
        this.sidedayno=d.getDay()+1;
      } else {
        this.sidedayno=0;
      }
    }
    console.log(found);
  }

  clearside() {
    this.sidedutystart='';
    this.sidedutyhours='';
    this.sideid='';
    this.sidedayno='';
    this.sidedate = '';
    this.sideyear = '';
    this.sidemonth = '';
  }

  clearsomeside() {
    this.sidedutystart='';
    this.sidedutyhours='';
    this.sideid='';
    this.sidedayno='';
  }

  async refreshCalendar(month,year) {
    
    this.clearside();
    this.itemData = {
      "month":month,
      "year":year,
    }
    await this.apiService.post('view-calendar', this.itemData).then((res: any) => {

        this.cmonth = month;
        this.cyear = year;
        this.calendardata=res.data;
        this.firstday = this.getFirstDayOfMonth(this.cyear, this.cmonth);
    });

  }

  async upload() {
    this.vcount=0;
    this.ecode='';
    this.ename='';

    if(this.dayname=='' || this.dayname==undefined) {
      this.vcount=1;
      this.edayname='Please select Day';
    }
    if(this.dutystart=='' || this.dutystart==undefined) {
      this.vcount=1;
      this.edutystart='Please enter Duty Start Time';
    }
    if(this.dutyhours=='' || this.dutyhours==undefined) {
      this.vcount=1;
      this.edutyhours='Please enter total Duty Hours';
    }
    if(this.vcount==0) {
      this.itemData = {
        "dayname":this.dayname,
        "dutystart":this.dutystart,
        "dutyhours":this.dutyhours,
      }
      await this.apiService.post('add-calendar', this.itemData).then((res: any) => {
          this.btntxt="Saving Calendar..."
          this.btndisabled=1;
          if(res.valid=='success') {
            this.dayname='';
            this.dutystart='';
            this.dutyhours='';
            this.btndisabled=0;
            this.getCalendar();
            this.btntxt="Add Calendar";
            this.onTab(1);
          } else {
            this.message=res.msg;
          }
      });
    }
  }

  async update() {
    this.vcount=0;
    this.esidemsg='';
    if(this.sidedayno=='' || this.sidedayno==undefined) {
      this.vcount=1;
      this.esidemsg='Please select a day';
    }
    if(this.sidedutystart=='' || this.sidedutystart==undefined) {
      this.vcount=1;
      this.esidemsg='Please enter Duty Start Time';
    }
    if(this.sidedutyhours=='' || this.sidedutyhours==undefined) {
      this.vcount=1;
      this.esidemsg='Please enter Duty Hours';
    }
    if(this.vcount==0) {
      this.itemData = {
        "dayname":this.sidedayno,
        "dutystart":this.sidedutystart,
        "dutyhours":this.sidedutyhours,
        "dutydate":this.myeditdate,
        "id":this.sideid,
      }
      await this.apiService.post('update-calendar', this.itemData).then((res: any) => {

            this.refreshCalendar(this.cmonth,this.cyear);
            this.esidemsg=res.msg;

      });
    }
  }

  async clearmonth() {
    var months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];

    var selectedMonthName = months[this.cmonth-1];
    if(confirm("Are you sure you want to clear data for the month " + selectedMonthName + ", " + this.cyear)) {
      this.itemData = {
        "month":this.cmonth,
        "year":this.cyear,
      }
      await this.apiService.post('remove-calendar-month', this.itemData).then((res: any) => {
            this.refreshCalendar(this.cmonth,this.cyear);
            this.clearside();
            this.esidemsg=res.msg;
      });
    }
  }

  async deleteAll() {
    if(confirm("Are you sure you want to clear data for the year " + this.cyear)) {
      this.itemData = {
        "year":this.cyear,
      }
      await this.apiService.post('remove-calendar-year', this.itemData).then((res: any) => {
            this.refreshCalendar(this.cmonth,this.cyear);
            this.clearside();
      });
    }
  }

  async clear() {
    if(confirm("Are you sure you want to clear this data?")) {
      this.itemData = {
        "id":this.sideid,
      }
      await this.apiService.post('remove-calendar', this.itemData).then((res: any) => {
            this.clearsomeside();
            this.refreshCalendar(this.cmonth,this.cyear);
            this.esidemsg=res.msg;

      });
    }
  }

}


