import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-frequency',
  templateUrl: './frequency.component.html',
  styleUrls: ['./frequency.component.scss']
})
export class FrequencyComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  frequencyname:any = '';

  userid:any = '';

  btntxt:any = 'Add Frequency';
  savetxt:any = 'Save';

  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  entities:any;

  vcount:any = 0;
  ename:any = '';

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getEntity();
  }

  ngOnInit(): void {
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getEntity() {
      this.itemData = {

      }
      this.apiService.post('frequencies', this.itemData).then((res: any) => {
          this.entities=res.data;
      });
  }

  upload() {
    this.vcount=0;
    this.ename='';

    if(this.frequencyname=='' || this.frequencyname==undefined) {
      this.vcount=1;
      this.ename='Please enter Frequency Name';
    }
    if(this.frequencyname.length>15) {
      this.vcount=1;
      this.ename='Max characters allowed is 15';
    }
    if(this.vcount==0) {
      this.itemData = {
        "frequencyname":this.frequencyname,
      }
      this.apiService.post('frequency', this.itemData).then((res: any) => {
          this.btntxt="Saving Frequency..."
          this.btndisabled=1;
          if(res.status==200) {
            this.frequencyname='';
            this.btndisabled=0;
            this.getEntity();
            this.btntxt="Add Frequency";
            this.onTab(1);
          }
      });
    }
  }

}
