import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;

  
  email:any='';
  mobile:any='';
  city:any='';
  country:any='';
  tin:any = '';
  btntxt:any = 'Add Purchase';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  invoice:any='';
  vendor:any='';
  tdate:any ='';

  //validation variables
  vcount:any = 0;
  einvoice:any = 0;
  evendor:any = 0;

  cid:any;
  vendors:any;
  productlist:any;

  product:any;
  pamount:any;
  pqty:any;
  ptax:any;
  pdiscount:any;
  ptotal:any;

  subtotal:any = 0;
  discount:any = 0;
  tax:any = 0;
  total:any = 0;
  delivery:any = 0;

  remarks:any = ''

  vendorcurrency:any = '';

  purchaseForm: FormGroup;
  //items: FormArray;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) { 
    this.cid = sessionStorage.getItem("cid");
    this.getpurchase();
    this.getVendors();
    this.getproducts();
  }

  getVendors() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('vendors', this.itemData).then((res: any) => {
      this.vendors=res.data;
    });
  }

  getproducts() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('products', this.itemData).then((res: any) => {
          this.productlist=res.data;                          
      });
  }

  ngOnInit(): void {
      this.purchaseForm = this.formBuilder.group({
        items: this.formBuilder.array([ this.createItem() ])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
        product: '',
        pamount: '',
        pqty: '',
        ptax: '',
        pdiscount: '',
        ptotal: '',
    });
  }

  addItem(): void {
    //this.items = this.purchaseForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  getRates(val,index) {
    let selectedProducts;
    this.productlist.forEach((product) => {
      console.log(product.id);
      console.log(val);
      if (val == product.id) {
        selectedProducts = product;
        
        this.items.at(index).get('pamount').setValue(product.price);
        this.items.at(index).get('pqty').setValue('1');
        this.items.at(index).get('pdiscount').setValue('0');
        this.items.at(index).get('ptax').setValue('0');
        this.items.at(index).get('ptotal').setValue(product.price);
        this.subtotal=product.price;
      }
    })
    this.calculate(val, index);
  }

  get items() {
    return this.purchaseForm.get('items') as FormArray
  }

  calculate(val, index) {
    if(isNaN(this.items.at(index).get('pamount').value)) {
      this.items.at(index).get('pamount').setValue(0);
    }
    if(isNaN(this.items.at(index).get('pqty').value)) {
      this.items.at(index).get('pqty').setValue(1);
    }
    if(isNaN(this.items.at(index).get('pdiscount').value)) {
      this.items.at(index).get('pdiscount').setValue(0);
    }
    if(isNaN(this.items.at(index).get('ptax').value)) {
      this.items.at(index).get('ptax').setValue(0);
    }
    if(isNaN(this.items.at(index).get('ptotal').value)) {
      this.items.at(index).get('ptotal').setValue(0);
    }
    const mytotal= +(this.items.at(index).get('pqty').value * (+this.items.at(index).get('pamount').value + +this.items.at(index).get('ptax').value)) - +this.items.at(index).get('pdiscount').value;
    this.items.at(index).get('ptotal').setValue(mytotal);
    this.calclulateTotal();
  }

  calclulateTotal() {
    const sumArray = [];
    for (const control of this.items.controls) {
      sumArray.push(control.value['ptotal'])
    }

    this.subtotal = this.getTotalAmount(sumArray);
    this.total=+this.subtotal + -this.discount + +this.tax + +this.delivery;
  }

  getTotalAmount = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
  }

  selectVendor(val) {
    this.vendor=val;
    this.vendors.forEach((vendor) => {
      if (val == vendor.id) {
        this.vendorcurrency=vendor.currency;
      }
    })
  }

  getpurchase() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('purchases', this.itemData).then((res: any) => {
          this.catlist=res.data;                          
      });
  }

  addpurchase() {
      this.einvoice=0;
      this.evendor=0;
      this.vcount=0;
      if(this.invoice=='') {
        this.einvoice=1;
        this.vcount=1;
      }
      if(this.vendor=='' || this.vendor==undefined) {
        this.evendor=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.cid,
            "invoiceno":this.invoice,
            "vendor":this.vendor,
            "name":'',
            "address":'',
            "phone":'',
            "totalitems":5,
            "subtotal":this.subtotal,
            "tax":this.tax,
            "discount":this.discount,
            "delivery":this.delivery,
            "total":this.total,
            "remarks":this.remarks,
            "tdate":this.tdate,
            "currency":this.vendorcurrency,
            "items": this.purchaseForm.value
          }
          this.apiService.post('purchase', this.itemData).then((res: any) => {
            this.btntxt="Adding Purchase..."
              //this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Purchase';
                this.btndisabled=0;
                this.catlist=res.data; 
                //this.onTab(1);
              }                          
          });
      }
  }

}
