import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = 0;
  cost:any = 0;

  btntxt:any = 'Add Project';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  clientlist:any;
  statuslist:any;
  projectlist:any;
  pid:any;

  estartdate:any = 0;
  eenddate:any = 0;
  vcount:any = 0;
  ename:any = 0;
  eclient:any = 0;
  estatus:any = 0;

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getClients();
    this.getStatus();
    this.getProjects();
  }

  ngOnInit(): void {
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getClients() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('clients', this.itemData).then((res: any) => {
          this.clientlist=res.data;
      });
  }

  getProjects() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('projects', this.itemData).then((res: any) => {
          this.projectlist=res.data;
      });
  }

  getStatus() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('allstatus', this.itemData).then((res: any) => {
          this.statuslist=res.data;
      });
  }

  edit(id) {
    this.router.navigate(['/project-edit/', { id: id }]);
  }

  upload() {
    this.vcount=0;
    this.estartdate=0;
    this.eenddate=0;
    this.ename=0;
    if(this.start_date=='' || this.start_date==undefined) {
      this.vcount=1;
      this.estartdate=1;
    }
    if(this.end_date=='' || this.end_date==undefined) {
      this.vcount=1;
      this.eenddate=1;
    }
    if(this.name=='' || this.name==undefined) {
      this.vcount=1;
      this.ename=1;
    }
    if(this.vcount==0) {
      this.itemData = {
        "id":this.cid,
        "name":this.name,
        "start_date":this.start_date,
        "end_date":this.end_date,
      }
      this.apiService.post('project', this.itemData).then((res: any) => {
          this.btntxt="Saving Project..."
          this.btndisabled=1;
          if(res.status==200) {
            this.forclient='';
            this.name='';
            this.start_date='';
            this.end_date='';
            this.btndisabled=0;
            this.getProjects();
            this.btntxt="Add Project";
            this.onTab(1);
          }
      });
    }
  }

  view(id) {
    this.router.navigate(['/project-details/', { queryParams: { id: id }}]);
  }

}
