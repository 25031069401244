import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-pr-clients',
  templateUrl: './pr-clients.component.html',
  styleUrls: ['./pr-clients.component.scss']
})
export class PrClientsComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  name:any = '';
  companyname:any = '';
  mobile:any = '';
  email:any = '';

  btntxt:any = 'Add Client';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  clientlist:any;

  constructor(private apiService: ApiService) { 

    this.cid = sessionStorage.getItem("cid");
    this.getClients();

  }

  ngOnInit(): void {
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getClients() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('clients', this.itemData).then((res: any) => {
          this.clientlist=res.data;                        
      });
  }

  upload() {
    const formData = new FormData();
    if (this.profileimage !== undefined) {
      if(this.profileimage) {
        formData.append('logofile', this.profileimage);
      } else {
        formData.append('logofile', '');
      }
      formData.append('id', this.cid);
      formData.append('company', this.companyname);
      formData.append('name', this.name);
      formData.append('phone', this.mobile);
      formData.append('email', this.email);
      this.apiService.post('client', formData).then((res: any) => {
        this.btntxt="Saving Client..."
          this.btndisabled=1;
          if(res.status==200) {  
            this.companyname='';
            this.name='';           
            this.mobile=''; 
            this.email=''; 
            this.btndisabled=0;
            this.getClients();
            this.btntxt="Add Client";
            this.onTab(1);
          }                          
      });
    }
  }

}
