import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: false } }
  ]
})
export class LoginComponent implements OnInit {

  email:any = '';
  password:any = '';
  btntxt:any = 'Sign In';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  userid:any;
  terms:any;
  //filepath:any = 'http://localhost:3000/api/images/';
  filepath:any = 'http://sstrust.perutions.com/api/images/';
  logon:any;
  mylogo:any;
  isforgot:any = 0;
  iscode:any = 0;
  femail:any = '';
  forgotmessage:any = '';
  changemessage:any = '';
  code:any = '';
  newpassword:any = '';
  constructor(private session: SessionService, private router: Router, private apiService: ApiService) {

    this.filepath = sessionStorage.getItem("filepath");
  }

  goDash() {

  }

  forgot() {
    this.isforgot=1;
  }

  backlogin() {
    this.isforgot=0;
  }

  ngOnInit(): void {
    this.userid=sessionStorage.getItem("userid");

    if(this.userid=='' || this.userid==undefined) {

    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  getpassword() {
    if(this.femail=='') {
        this.forgotmessage='Please fill email id';
    } else {
        this.itemData = {
          "email":this.femail,
        }
        this.apiService.post('get-password', this.itemData).then((res: any) => {
            this.forgotmessage=res.data;
            if(res.isinvalid=='0') {
              this.iscode=1;
            } else {
              this.iscode=0;
            }
        });
    }
  }

  changepassword() {
    if(this.femail=='' || this.code=='' || this.newpassword=='') {
        this.forgotmessage='Please fill all details';
    } else {
        this.itemData = {
          "email":this.femail,
          "code":this.code,
          "newpassword":this.newpassword
        }
        this.apiService.post('reset-password', this.itemData).then((res: any) => {
            this.changemessage=res.msg;
            this.iscode=2;
            if(res.isvalid=='1') {
              this.forgotmessage='';
              this.femail='';
              this.email='';
              this.code='';
              this.newpassword='';
            }
        });
    }
  }

  signin() {
      if(this.email=='' || this.password=='') {
          this.message='Please fill all the details';
      } else {
          this.btntxt="Signing In..."
          this.itemData = {
            "email":this.email,
            "password":this.password
          }
          this.apiService.post('login', this.itemData).then((res: any) => {
              this.btndisabled=1;
              if(res) {
                if(res.status==401) {
                  this.btntxt='Sign In';
                  this.message=res.error;
                  this.btndisabled=0;
                } else {
                  if(res.status==204) {
                    this.btntxt='Sign In';
                    this.btndisabled=0;
                    this.message=res.msg;
                  } else {
                    this.btntxt='Login Successful'
                    this.password='';
                    this.terms='';
                    sessionStorage.setItem("userid", res.id);
                    sessionStorage.setItem("name", res.name);
                    sessionStorage.setItem("role", res.role);
                    sessionStorage.setItem("company", res.company);
                    sessionStorage.setItem("phone", res.phone);
                    sessionStorage.setItem("cid", res.cid);
                    sessionStorage.setItem("mylogo", res.logo);
                    sessionStorage.setItem("token", res.token);
                    sessionStorage.setItem("permission", res.permission);
                    this.session.myPermission(res.permission);
                    this.session.myUserid(res.id);
                    this.session.myName(res.name);
                    this.session.myRole(res.role);
                    this.session.myCompany(res.company);
                    this.session.myCid(res.cid);
                    this.session.myLogo(res.logo);


                    if(res.logo) {
                      this.logon=res.logo.replace('public','');
                      this.logon = this.logon.replace('logo','');
                      this.mylogo=this.filepath + this.logon;
                      this.session.myLogo(this.mylogo);
                      sessionStorage.setItem("mylogo",this.mylogo);
                    } else {
                      this.session.myLogo('');
                    }

                    this.router.navigate(['/dashboard']);
                  }
                }
              } else {
                this.btntxt='Sign In';
                this.btndisabled=0;
                this.message=res.msg;
              }
          });
      }
  }




}
