import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab: boolean;

  name:any='';
  uname:any='';
  address:any='';
  uaddress:any='';
  email:any='';
  uemail:any='';
  mobile:any='';
  umobile:any='';
  role:any='';
  urole:any='';
  password:any='';
  btntxt:any = 'Add Staff';
  ubtntxt:any = 'Update Staff Details'
  itemData:any='';
  btndisabled:any = 0;
  message:any='';
  catlist:any='';

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  erole:any = 0;
  epassword:any = 0;
  eemail:any = 0;
  euname:any = 0;

  uvcount:any = 0;
  uename:any = 0;
  uerole:any = 0;
  ueemail:any = 0;

  cid:any;
  conversionlist:any;
  staffdet:any;
  currentstaff:any = '';
  myuserid:any;
  myrole:any;
  search:any = '';

  accountno:any = '';
  accountname:any = '';
  bank:any = '';
  branch:any = '';
  ifsc:any = '';
  basicsalary:any = '';
  ctc:any = '';
  countries:any = '';
  lastname:any = '';
  dob:any = '';
  doj:any = '';
  dol:any = '';
  department:any = '';
  country:any = '';
  departments:any = '';
  costcenters:any = '';
  costcenter:any = '';
  close1:any = '';
  close2:any = '';
  attendancetype:any = '';


  constructor(private apiService: ApiService,private router: Router,private activatedRoute: ActivatedRoute) {
    this.cid = sessionStorage.getItem("cid");
    this.myuserid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    if(this.myrole>1) {
      this.router.navigate(['/dashboard']);
    }

    this.myrole = sessionStorage.getItem("role");
    this.getvendor();
    this.getCountry();
    this.getDepartments();
    this.getCostcenter();
    this.getConversion();
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  ngOnInit(): void {
  }

  getCountry() {
      this.itemData = {

      }
      this.apiService.post('countries', this.itemData).then((res: any) => {
          this.countries=res.data;
      });
  }

  getDepartments() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('categories', this.itemData).then((res: any) => {
          this.departments=res.data;
      });
  }

  getCostcenter() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('cost-centres', this.itemData).then((res: any) => {
          this.costcenters=res.data;
      });
  }


  searchstaff(val:any) {
    this.itemData = {
      "id":this.cid,
      "search":val
    }
    this.apiService.post('staffs', this.itemData).then((res: any) => {
        this.catlist=res.data;
    });
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }else if (number == '3') {
      this.editTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
  }

  getvendor() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('staffs', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  getStaffDetail(id) {
    this.currentstaff=id;
    this.onTab(3);
      this.itemData = {
        "id":this.cid,
        "userid":id

      }
      this.apiService.post('staff-detail', this.itemData).then((res: any) => {
          this.staffdet=res.data;
          this.uname=res.data[0].name;
          this.uaddress=res.data[0].address;
          this.umobile=res.data[0].phone;
          this.uemail=res.data[0].email;
          this.urole=res.data[0].role;

          this.accountno=res.account[0].accountno;
          this.accountname=res.account[0].accountname;
          this.bank=res.account[0].bank;
          this.branch=res.account[0].branch;
          this.ifsc=res.account[0].ifsc;
          this.basicsalary=res.account[0].basic_salary;
          this.ctc=res.account[0].cost_to_company;
      });
  }

  addvendor() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.email=='') {
        this.eemail=1;
        this.vcount=1;
      }
      if(this.password=='' || this.password==undefined) {
        this.epassword=1;
        this.vcount=1;
      }
      if(this.role=='' || this.role==undefined) {
        this.erole=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "address":this.address,
            "mobile":this.mobile,
            "email":this.email,
            "role":this.role,
            "password":this.password,
          }
          this.apiService.post('staff', this.itemData).then((res: any) => {
            this.btntxt="Adding Staff..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Staff';
                this.btndisabled=0;
                this.catlist=res.data;
                this.name='';
                this.address='';
                this.onTab(1);
              }
          });
      }
  }

  removeStaff(id) {
    if(confirm("Are you sure you want to delete this Staff?")) {
        this.itemData = {
          "id":this.cid,
          "userid":id
        }
        this.apiService.post('remove-staff', this.itemData).then((res: any) => {
              this.catlist=res.data;
        });
    }
  }

  updateStaff() {
      this.euname=0;
      this.uvcount=0;
      if(this.uname=='') {
        this.uename=1;
        this.uvcount=1;
      }
      if(this.uemail=='') {
        this.ueemail=1;
        this.uvcount=1;
      }
      if(this.urole=='' || this.urole==undefined) {
        this.uerole=1;
        this.uvcount=1;
      }
      if(this.uvcount==0) {
          this.itemData = {
            "id":this.cid,
            "name":this.uname,
            "address":this.uaddress,
            "phone":this.umobile,
            "role":this.urole,
            "userid":this.currentstaff,
            "accountno":this.accountno,
            "accountname":this.accountname,
            "bank":this.bank,
            "branch":this.branch,
            "ifsc":this.ifsc,
            "basicsalary":this.basicsalary,
            "ctc":this.ctc
          }
          this.apiService.post('staff-edit', this.itemData).then((res: any) => {
            this.ubtntxt="Updating Staff Details..."
              this.btndisabled=1;
              if(res.status==200) {
                this.ubtntxt='Update Staff Details';
                this.btndisabled=0;
                this.uname='';
                this.uaddress='';
                this.umobile='';
                this.uemail='';
                this.urole='';
                this.currentstaff='';
                this.catlist=res.data;
                this.onTab(1);
              }
          });
      }
  }

}
