import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  entrytab:any = 1;

  btntxt:any = 'Add Entry';
  ubtntxt:any = 'Edit Entry';

  voucherno:any = '';
  tdate:any ='';
  etdate:any = 0;

  itemData:any;
  btndisabled:any = 0;
  message:any;

  vcount:any = 0;

  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  fromledgerlist:any='';
  toledgerlist:any = '';

  transactions:any = '';
  debitledger:any;
  creditledger:any;

  entryForm: FormGroup;

  total:any = 0;
  remarks:any = '';
  fy:any = '';
  ledgercredit:any = 0;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getTransactions();
    this.getLedgers();
  }

  ngOnInit(): void {
      this.entryForm = this.formBuilder.group({
        items: this.formBuilder.array([ this.createItem() ])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
        ledgerdebit: ['', [Validators.required]],
        narration: ['', [Validators.required]],
        amount: ['', [Validators.required]],
    });
  }

  addItem(): void {
    //this.items = this.purchaseForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    this.items.removeAt(index);
    //this.calculate(0, index);
  }

  get items() {
    return this.entryForm.get('items') as FormArray
  }

  getTransactions() {
      this.itemData = {
        "cid":this.cid
      }
      this.apiService.post('transactions', this.itemData).then((res: any) => {
          this.transactions=res.data;
      });
  }

  getLedgers() {
      this.itemData = {
        "cid":this.cid,
        "type":this.entrytab
      }
      this.apiService.post('entry-ledgers', this.itemData).then((res: any) => {
          this.debitledger=res.debit;
          this.creditledger=res.credit;
      });
  }

  async newEntry() {
      this.itemData = {
        "cid":this.cid,
        "voucherno":this.voucherno,
        "tdate":this.tdate,
        "ledgercredit":this.ledgercredit,
        "items": this.entryForm.value,
        "total":this.total,
        "remarks":this.remarks,
        "entrytype":this.entrytab
      }
      await this.apiService.post('entry-payments', this.itemData).then((res: any) => {
        this.voucherno='';
        this.tdate='';
        this.ledgercredit='';
        //this.entryForm.value='';
        this.entryForm = this.formBuilder.group({
          items: this.formBuilder.array([ this.createItem() ])
        });
        this.total=0;
        this.remarks='';
        this.getTransactions();
        this.onTab(1);
      });
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    //this.getSingle(id);
  }

  changeEntry(id:any) {
    this.entrytab=id;
    this.getLedgers();
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }





  calculate(val, index) {

    this.total=0;
    if(this.items.length>0) {
      if(isNaN(this.items.at(index).get('amount').value)) {
        this.items.at(index).get('amount').setValue(0);
      }
      for (const control of this.items.controls) {
        this.total=+this.total + +control.value['amount'];
        this.remarks=control.value['narration']
      }

    } else {
      this.total = 0;
    }
  }

}
