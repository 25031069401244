import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  currencyid:any = '';
  currencyname:any = '';
  currencyfraction:any = '';
  currencydecimal:any = '';

  userid:any = '';

  btntxt:any = 'Add Currency';
  savetxt:any = 'Save';

  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  currencies:any;

  vcount:any = 0;
  ename:any = '';
  ecode:any = '';
  efraction:any = '';
  edecimal:any = '';

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getCurrency();
  }

  ngOnInit(): void {
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getCurrency() {
      this.itemData = {

      }
      this.apiService.post('currencies', this.itemData).then((res: any) => {
          this.currencies=res.data;
      });
  }

  upload() {
    this.vcount=0;
    this.ecode='';
    this.ename='';
    this.efraction='';
    this.edecimal='';

    if(this.currencyname=='' || this.currencyname==undefined) {
      this.vcount=1;
      this.ename='Please enter Currency Name';
    }
    if(this.currencyname.length>50) {
      this.vcount=1;
      this.ename='Max characters allowed is 50';
    }
    if(this.currencyid=='' || this.currencyid==undefined) {
      this.vcount=1;
      this.ecode='Please enter Currency ID';
    }
    if(this.currencyid.length>3) {
      this.vcount=1;
      this.ecode='Max characters allowed is 3';
    }
    if(this.currencyfraction=='' || this.currencyfraction==undefined) {
      this.vcount=1;
      this.efraction='Please enter Currency Fraction';
    }
    if(this.currencyfraction.length>15) {
      this.vcount=1;
      this.efraction='Max characters allowed is 15';
    }
    if(this.currencydecimal=='' || this.currencydecimal==undefined) {
      this.vcount=1;
      this.edecimal='Please enter Currency Decimal';
    }
    if(this.currencydecimal.length>2) {
      this.vcount=1;
      this.edecimal='Max characters allowed is 2';
    }
    console.log(this.vcount);
    console.log(this.edecimal);
    if(this.vcount==0) {
      this.itemData = {
        "currencyname":this.currencyname,
        "currencyid":this.currencyid,
        "currencyfraction":this.currencyfraction,
        "currencydecimal":this.currencydecimal,
      }
      this.apiService.post('currency', this.itemData).then((res: any) => {
          this.btntxt="Saving Currency..."
          this.btndisabled=1;
          if(res.status==200) {
            this.currencyid='';
            this.currencyname='';
            this.currencyfraction='';
            this.currencydecimal='';
            this.btndisabled=0;
            this.getCurrency();
            this.btntxt="Add Currency";
            this.onTab(1);
          }
      });
    }
  }

}
