import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-quotationview',
  templateUrl: './quotationview.component.html',
  styleUrls: ['./quotationview.component.scss']
})
export class QuotationviewComponent implements OnInit {

  id:any;
  cid:any;
  details:any;
  qitems:any;
  subtotal:any = 0;
  discount:any = 0;
  tax:any = 0;
  total:any = 0;
  itemData:any;
  qvat:any;
  userid:any;
  data:any;
  username:any;
  userphone:any;
  useremail:any;
  logon:any;
  bannern:any;
  mylogo:any;
  mybanner:any;
  headern:any;
  myheader:any;
  filepath:any = '';
  suppphone:any='';
  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute) { 
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.suppphone = sessionStorage.getItem("phone");

    this.filepath = sessionStorage.getItem("filepath");
    this.getQuotation();
    this.getSettings();
    this.getLogo();
  }

  ngOnInit(): void {
  }

  getQuotation() {
      this.itemData = {
        "id":this.cid,
        "quotation":this.id
      }
      this.apiService.post('quotation-view', this.itemData).then((res: any) => {
          this.details=res.data;  
          this.qitems=res.items;
          this.subtotal=res.data[0].mysum;  
          this.discount=res.data[0].discount;
          this.tax=res.data[0].myvat;
          this.total=res.data[0].total;       
          this.username=res.data[0].username;        
          this.userphone=res.data[0].userphone;       
          this.useremail=res.data[0].useremail;            
          console.log(this.details); 
      });
  }

  getSettings() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('settings', this.itemData).then((res: any) => {
      this.qvat=res.data[0].vat;
      this.data=res.data[0].terms;
    });
  }

  getLogo() {
    this.apiService.get('getlogo/'+this.cid).then((res: any) => {
      if(res.logo!='' && res.logo!=undefined && res.logo!=null) {
        this.logon=res.logo.replace('public',''); 
        this.logon = this.logon.replace('logo',''); 
        this.mylogo=this.filepath + this.logon;
      }  

      if(res.banner!='' && res.banner!=undefined && res.banner!=null) {
        this.bannern=res.banner.replace('public',''); 
        this.bannern = this.bannern.replace('logo',''); 
        this.mybanner=this.filepath + this.bannern; 
      }   

      if(res.header!='' && res.header!=undefined && res.header!=null) {
        this.headern=res.header.replace('public',''); 
        this.headern = this.headern.replace('logo',''); 
        this.myheader=this.filepath + this.headern;    
      }             
    });
  }

  onPrint(divName) {
     const printContents = document.getElementById(divName).innerHTML;
     const originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     //document.body.innerHTML = originalContents;
  }

}
