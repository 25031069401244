import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-daybook',
  templateUrl: './daybook.component.html',
  styleUrls: ['./daybook.component.scss']
})
export class DaybookComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  entrytab:any = 1;

  btntxt:any = 'Add Entry';
  ubtntxt:any = 'Edit Entry';

  voucherno:any = '';
  tdate:any ='';
  etdate:any = 0;

  itemData:any;
  btndisabled:any = 0;
  message:any;

  vcount:any = 0;

  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  fromledgerlist:any='';
  toledgerlist:any = '';

  transactions:any = '';
  debitledger:any;
  creditledger:any;

  entryForm: FormGroup;

  total:any = 0;
  remarks:any = '';

  credittotal:any = 0;
  debittotal:any = 0;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getTransactions();
  }

  ngOnInit(): void {

  }




  getTransactions() {
      this.itemData = {
        "cid":this.cid,
        "tdate":this.tdate,
        "search":this.search
      }
      this.apiService.post('daybook', this.itemData).then((res: any) => {
          this.transactions=res.data;
          this.findsum(res.data);
      });
  }

  searchTransactions() {
    this.itemData = {
      "cid":this.cid,
      "tdate":this.tdate,
      "search":this.search
    }
    this.apiService.post('daybook', this.itemData).then((res: any) => {
        this.transactions=res.data;
        this.findsum(res.data);
    });
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  findsum(data){
    this.credittotal=0;
    this.debittotal=0;
    for(let j=0;j<data.length;j++){
         this.credittotal+= data[j].credit;
         this.debittotal+= data[j].debit;
    }
  }


}
