import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HrDashboardComponent } from './HRMS/hr-dashboard/hr-dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { CategoryComponent } from './hrms/category/category.component';
import { ProjectsComponent } from './project/projects/projects.component';
import { PermissionComponent } from './permission/permission.component';
import { ZoneComponent } from './zone/zone.component';
import { CountryComponent } from './country/country.component';
import { CurrencyComponent } from './currency/currency.component';
import { UnitComponent } from './unit/unit.component';
import { EntityComponent } from './entity/entity.component';
import { FrequencyComponent } from './frequency/frequency.component';
import { StaffComponent } from './staff/staff.component';
import { CalendarComponent } from './calendar/calendar.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: HrDashboardComponent,
    data: { title: ':: JNJ :: Home' }
  },
  {
    path: 'category',
    component: CategoryComponent,
    data: { title: ':: JNJ :: Category' }
  },
  {
    path: 'departments',
    component: CategoryComponent,
    data: { title: ':: JNJ :: Departments' }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: ':: JNJ :: Settings' }
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: { title: ':: JNJ :: Projects' }
  },
  {
    path: 'permission',
    component: PermissionComponent,
    data: { title: ':: JNJ :: SS Trust Permissions' }
  },
  {
    path: 'cost-centre',
    component: ZoneComponent,
    data: { title: ':: JNJ :: Cost Centre Management' }
  },
  {
    path: 'countries',
    component: CountryComponent,
    data: { title: ':: JNJ :: Countries' }
  },
  {
    path: 'currencies',
    component: CurrencyComponent,
    data: { title: ':: JNJ :: Currencies' }
  },
  {
    path: 'units',
    component: UnitComponent,
    data: { title: ':: JNJ :: Units' }
  },
  {
    path: 'entities',
    component: EntityComponent,
    data: { title: ':: JNJ :: Entities' }
  },
  {
    path: 'frequencies',
    component: FrequencyComponent,
    data: { title: ':: JNJ :: Frequencies' }
  },
  {
    path: 'employee',
    component: StaffComponent,
    data: { title: ':: JNJ :: Employee' }
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    data: { title: ':: JNJ :: Calendar' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
  ];

}
