import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  //add
  name:any='';
  description:any='';
  sku:any='';
  mrp:any='0';
  selling:any='0';
  stock:any='0';
  sellingn:any='0';
  stockn:any='0';
  btntxt:any = 'Add Product';

  //edit
  pname:any='';
  pdescription:any='';
  psku:any='';
  pmrp:any='0';
  pselling:any='0';
  pstock:any='0';
  psellingn:any='0';
  pstockn:any='0';
  pcategory:any;
  ebtntxt:any = 'Edit Product';

  itemData:any;
  btndisabled:any = 0;
  message:any;
  productlist:any;

  ename:any = 0;
  emrp:any = 0;
  eselling:any = 0;
  emrpn:any=0;
  esellingn:any=0;
  estockn:any=0;

  //edit validation variables
  pename:any = 0;
  pemrp:any = 0;
  peselling:any = 0;
  pemrpn:any=0;
  pesellingn:any=0;
  pestockn:any=0;
  pecategory:any;

  cid:any;
  catlist:any;

  category:any='';
  ecategory:any = 0;
  currentedit:any='0';

  vcount:any = 0;
  search:any;
  myrole:any;
  vatpercentage:any = 5;
  pvatpercentage:any = 0;

  constructor(private apiService: ApiService,private session: SessionService,) { 
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getproducts();
    this.getcategories();

  }

  getcategories() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('categories', this.itemData).then((res: any) => {
          this.catlist=res.data;                          
      });
  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentedit=id;
    this.getSingleproduct(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  sweettalert7() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: "rgb(220, 53, 69)",
      confirmButtonText: 'Yes, delete it!',

    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  getproducts() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('products', this.itemData).then((res: any) => {
          this.productlist=res.data;                          
      });
  }

  getSingleproduct(id) {
      this.itemData = {
        "id":this.cid,
        "pid":id
      }
      this.apiService.post('get-product', this.itemData).then((res: any) => {
          this.pname=res.data[0].name;
          this.pcategory=res.data[0].categoryid;
          this.psku=res.data[0].sku;   
          this.pdescription=res.data[0].description;
          this.pmrp=res.data[0].mrp;
          this.pselling=res.data[0].price;
          this.pstock=res.data[0].stock;   
          this.pvatpercentage=res.data[0].vat;                    
      });
  }

  searchproducts(searchval) {
    this.itemData = {
        "id":this.cid,
        search:searchval
      }
      this.apiService.post('search-product', this.itemData).then((res: any) => {
          this.productlist=res.data;                          
      });
  }

  removeProduct(id) {
    if(confirm("Are you sure you want to delete this Product?")) {
        this.itemData = {
          "id":this.cid,
          "pid":id
        }
        this.apiService.post('remove-product', this.itemData).then((res: any) => {
              this.productlist=res.data;                        
        });
    }
  }

  async addproduct() {
      this.ename=0;
      this.emrp=0;
      this.eselling=0;
      this.emrpn=0;
      this.esellingn=0;
      this.estockn=0;
      this.ecategory=0;
      this.vcount=0;
      if(this.category=='') {
        this.ecategory=1;
        this.vcount=1;
      }
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.mrp=='') {
        this.emrp=1;
        this.vcount=1;
      }
      if(this.selling=='') {
        this.eselling=1;
        this.vcount=1;
      }
      if(isNaN(this.mrp)) {
        this.emrpn=1;
        this.vcount=1;
      }
      if(isNaN(this.selling)) {
        this.esellingn=1;
        this.vcount=1;
      }
      if(isNaN(this.stock)) {
        this.estockn=1;
        this.vcount=1;
      }
      if(this.stock=='' || this.stock==undefined) {
        this.stock=0;
      }
      if(this.vcount==0) {
        this.session.myLoader(1);
          this.itemData = {
            "name":this.name,
            "sku":this.sku,
            "description":this.description,
            "mrp":this.mrp,
            "selling":this.selling,
            "stock":this.stock,
            "cid":this.cid,
            "category":this.category,
            "vat":this.vatpercentage
          }
          await this.apiService.post('new-product', this.itemData).then((res: any) => {
            this.btntxt="Adding Product..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Product';
                this.btndisabled=0;
                this.productlist=res.data; 
                this.name='';
                this.sku='';
                this.description='';
                this.mrp='';
                this.selling='';
                this.stock='';
                this.session.myLoader(0);
                this.onTab(1);
              }                          
          });
      }
  }

  async editproduct() {
      this.pename=0;
      this.pemrp=0;
      this.peselling=0;
      this.pemrpn=0;
      this.pesellingn=0;
      this.pestockn=0;
      this.pecategory=0;
      this.vcount=0;
      if(this.pcategory=='') {
        this.pecategory=1;
        this.vcount=1;
      }
      if(this.pname=='') {
        this.pename=1;
        this.vcount=1;
      }
      if(this.pmrp=='') {
        this.pemrp=1;
        this.vcount=1;
      }
      if(this.pselling=='') {
        this.peselling=1;
        this.vcount=1;
      }
      if(isNaN(this.pmrp)) {
        this.pemrpn=1;
        this.vcount=1;
      }
      if(isNaN(this.pselling)) {
        this.pesellingn=1;
        this.vcount=1;
      }
      if(isNaN(this.pstock)) {
        this.pestockn=1;
        this.vcount=1;
      }
      if(this.pstock=='' || this.pstock==undefined) {
        this.pstock=0;
      }
      if(this.vcount==0) {
        this.session.myLoader(1);
          this.itemData = {
            "pid":this.currentedit,
            "name":this.pname,
            "sku":this.psku,
            "description":this.pdescription,
            "mrp":this.pmrp,
            "selling":this.pselling,
            "stock":this.pstock,
            "cid":this.cid,
            "category":this.pcategory,
            "vat":this.pvatpercentage
          }
          await this.apiService.post('edit-product', this.itemData).then((res: any) => {
            this.ebtntxt="Editing Product..."
              this.btndisabled=1;
              if(res.status==200) {
                this.ebtntxt='Edit Product';
                this.btndisabled=0;
                this.productlist=res.data; 
                this.pname='';
                this.psku='';
                this.pdescription='';
                this.pmrp='';
                this.pselling='';
                this.pstock='';
                this.session.myLoader(0);
                this.onTab(1);
              }                          
          });
      }
  }

}
