import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overtimerate',
  templateUrl: './overtimerate.component.html',
  styleUrls: ['./overtimerate.component.scss']
})
export class OvertimerateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
