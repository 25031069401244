import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab: boolean;

  name:any='';
  address:any='';
  email:any='';
  mobile:any='';
  city:any='';
  country:any='';
  tin:any = '';
  btntxt:any = 'Add Customer';

  uname:any='';
  uaddress:any='';
  uemail:any='';
  umobile:any='';
  ucity:any='';
  ucountry:any='';
  utin:any = '';
  ubtntxt:any = 'Edit Customer';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  ecountry:any = 0;
  eemail:any = 0;

  uename:any = 0;
  uecountry:any = 0;
  ueemail:any = 0;

  cid:any;
  conversionlist:any;
  currentedit:any;
  search:any;
  myrole:any;
  constructor(private apiService: ApiService,private session: SessionService) { 
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getvendor();
    this.getConversion();
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  ngOnInit(): void {
  }

  getsearch(searchval) {
    this.itemData = {
      "id":this.cid,
      search:searchval
    }
    this.apiService.post('search-customers', this.itemData).then((res: any) => {
        this.catlist=res.data;                          
    });
  }

  edit(id) {
    this.currentedit=id;
    this.onTab(3);
    this.SinglegetCustomer(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  getvendor() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('customers', this.itemData).then((res: any) => {
          this.catlist=res.data;                          
      });
  }

  SinglegetCustomer(id) {
    this.itemData = {
        "id":this.cid,
        "cid":this.currentedit
      }
      this.apiService.post('get-customer', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.uaddress=res.data[0].address;
          this.umobile=res.data[0].phone;
          this.uemail=res.data[0].email;
          this.ucity=res.data[0].city; 
          this.ucountry=res.data[0].country;
          this.utin=res.data[0].tin;                         
      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Customer?")) {
        this.itemData = {
          "id":this.cid,
          "cid":id
        }
        this.apiService.post('remove-customer', this.itemData).then((res: any) => {
              this.catlist=res.data;                        
        });
    }
  }

  async addvendor() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.email!='' && this.email!=undefined) {
        if(!this.validateEmail(this.email)) {
          this.eemail=1;
          this.vcount=1;
        }
      }
      if(this.country=='' || this.country==undefined) {
        this.ecountry=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "address":this.address,
            "mobile":this.mobile,
            "email":this.email,
            "city":this.city,
            "country":this.country,
            "tin":this.tin,
          }
          await this.apiService.post('customer', this.itemData).then((res: any) => {
            this.btntxt="Adding Customer..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Customer';
                this.btndisabled=0;
                this.catlist=res.data; 
                this.name='';
                this.address='';
                this.session.myLoader(0);
                this.onTab(1);
              }                          
          });
      }
  }

  async editCustomer() {
      this.uename=0;
      this.vcount=0;
      if(this.uname=='') {
        this.uename=1;
        this.vcount=1;
      }
      if(this.uemail!='' && this.uemail!=undefined) {
        if(!this.validateEmail(this.uemail)) {
          this.ueemail=1;
          this.vcount=1;
        }
      }
      if(this.ucountry=='' || this.ucountry==undefined) {
        this.uecountry=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.uname,
            "address":this.uaddress,
            "mobile":this.umobile,
            "email":this.uemail,
            "city":this.ucity,
            "country":this.ucountry,
            "tin":this.utin,
            "cid":this.currentedit
          }
          await this.apiService.post('edit-customer', this.itemData).then((res: any) => {
            this.ubtntxt="Editing Customer..."
              this.btndisabled=1;
              if(res.status==200) {
                this.ubtntxt='Edit Customer';
                this.btndisabled=0;
                this.catlist=res.data; 
                this.uname='';
                this.uaddress='';
                this.umobile='';
                this.uemail='';
                this.ucity='';
                this.ucountry='';
                this.utin='';
                this.session.myLoader(0);
                this.onTab(1);
              }                          
          });
      }
  }

  validateEmail(email) {
   const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return regularExpression.test(String(email).toLowerCase());
  }

}
