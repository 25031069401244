import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-quotationedit',
  templateUrl: './quotationedit.component.html',
  styleUrls: ['./quotationedit.component.scss']
})
export class QuotationeditComponent implements OnInit {

  contactTab: boolean = true;
  chatTab: boolean;


  email:any='';
  mobile:any='';
  city:any='';
  country:any='';
  tin:any = '';
  btntxt:any = 'Edit Quotation';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  invoice:any='';
  buyerref:any = '';
  validity:any = '';
  paymentterms:any = '';
  deliveryterms:any = '';
  customer:any='';
  tdate:any ='';

  //validation variables
  vcount:any = 0;
  einvoice:any = 0;
  ebuyerref:any = 0;
  etdate:any = 0;
  evalidity:any = 0;
  epaymentterms:any = 0;
  edeliveryterms:any = 0;
  ecustomer:any = 0;

  cid:any;
  customers:any;
  productlist:any;

  product:any;
  pamount:any;
  pqty:any;
  ptax:any;
  pdiscount:any;
  ptotal:any;

  subtotal:any = 0;
  discount:any = 0;
  tax:any = 0;
  total:any = 0;
  delivery:any = 0;
  vat:any;

  remarks:any = '';

  customercurrency:any = '';

  purchaseForm: FormGroup;
  currencylist:any;
  userid:any;
  eitems:any = 0;
  //items: FormArray;
  invmonth:any;
  invyear:any;
  search:any;
  myrole:any;

  details:any;
  qitems:any;
  qvat:any;

  id:any;
  count:any = 0;

  username:any = '';
  userphone:any = '';
  useremail:any = '';

  quoteid:any = '';
  certificates:any = '';
  ndate:any = 0;
  newdata:any = '';

  constructor(private session: SessionService,private datePipe: DatePipe, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute,) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    this.getpurchase();
    this.getcustomers();
    this.getproducts();
    this.getConversion();
    this.getSettings();
    this.getQuotation();
    this.getCertificates();

    this.tdate = new Date();
    this.tdate = this.datePipe.transform(this.tdate,"yyyy-MM-dd");


  }

  async getQuotation() {
      this.session.myLoader(1);
      this.itemData = {
        "id":this.cid,
        "quotation":this.id
      }
      await this.apiService.post('quotation-view', this.itemData).then((res: any) => {
          this.details=res.data;
          this.qitems=res.items;
          this.invoice=res.data[0].quoteno;
          this.buyerref=res.data[0].buyerref;
          this.customer=res.data[0].customer;
          this.validity=res.data[0].validity;
          this.paymentterms=res.data[0].paymentterm;
          this.deliveryterms=res.data[0].deliveryterm;
          this.tdate=res.data[0].ntdate;
          this.newdata=res.data[0].ntdate;
          this.subtotal=res.data[0].subtotal;
          this.discount=res.data[0].discount;
          //this.total=res.data[0].total;
          this.total=res.data[0].subtotal;
          this.subtotal=res.data[0].subtotal;
          this.tax=res.data[0].tax;
          this.delivery=res.data[0].delivery;
          this.username=res.data[0].username;
          this.userphone=res.data[0].userphone;
          this.useremail=res.data[0].useremail;
          this.quoteid = res.data[0].id;
          console.log(this.tdate);

          this.count=0;
          this.qitems.forEach( (element) => {
            if(this.count==0) {
              this.purchaseForm = this.formBuilder.group({
                items: this.formBuilder.array([ this.editItemlist(element) ])
              });
            } else {
              this.items.push(this.editItemlist(element));
            }
            this.count=1;
          });

          console.log(this.purchaseForm);
          this.session.myLoader(0);

      });
  }

  getSettings() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('settings', this.itemData).then((res: any) => {
      this.vat=res.data[0].vat;
      this.validity=res.data[0].validity;
      this.paymentterms=res.data[0].paymentterms;
      this.deliveryterms=res.data[0].deliveryterms;
      this.customercurrency=res.data[0].currencysymbol;
    });
  }

  getCertificates() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('get-certificates', this.itemData).then((res: any) => {
      this.certificates=res.data;
    });
  }

  getsearch(searchval) {
    this.itemData = {
      "id":this.cid,
      search:searchval
    }
    this.apiService.post('search-quotations', this.itemData).then((res: any) => {
        this.catlist=res.data;
    });
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.currencylist=res.data;
    });
  }

  getcustomers() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('customers', this.itemData).then((res: any) => {
      this.customers=res.data;
    });
  }

  getproducts() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('products', this.itemData).then((res: any) => {
          this.productlist=res.data;
      });
  }

  ngOnInit(): void {
      this.purchaseForm = this.formBuilder.group({
        //items: this.formBuilder.array([ this.createItem() ])
      });
      console.log(this.purchaseForm);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
        product: ['', [Validators.required]],
        dummy:'',
        certificate: '',
        ddate:'',
        dsku:'',
        supplierprice: '',
        currency: '',
        landing: '',
        margin: '',
        pamount: ['', [Validators.required]],
        pqty: ['', [Validators.required]],
        ptax: '',
        pdiscount: '',
        ptotal: ['', [Validators.required]],
    });
  }

  editItemlist(item): FormGroup {
    console.log(item);
    return this.formBuilder.group({
        product: [item.productid, [Validators.required]],
        dummy:item.dummy,
        certificate: item.certificate,
        ddate:item.deliverydate,
        dsku:item.units,
        supplierprice: item.supprice,
        currency: item.currency,
        landing: item.landing,
        margin: item.margin,
        pamount: [item.amount, [Validators.required]],
        pqty: [item.qty, [Validators.required]],
        ptax: item.tax,
        pdiscount: item.discount,
        ptotal: [item.total, [Validators.required]],
    });
  }

  addItem(): void {
    //this.items = this.purchaseForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    this.items.removeAt(index);
    this.calculate(0, 0);
  }

  getRates(val,index) {
    let selectedProducts;
    this.productlist.forEach((product) => {
      if (val == product.id) {
        selectedProducts = product;
        this.items.at(index).get('dummy').setValue(product.dummy);
        this.items.at(index).get('supplierprice').setValue(product.price);
        this.items.at(index).get('pamount').setValue(product.price);
        this.items.at(index).get('pqty').setValue('1');
        this.items.at(index).get('pdiscount').setValue('0');
        this.items.at(index).get('ptax').setValue(product.vat);
        this.items.at(index).get('ptotal').setValue(product.price);
        this.items.at(index).get('dsku').setValue(product.sku);
        this.subtotal=product.price;
      }
      if(val==-1) {
        this.items.at(index).get('ptax').setValue(5);
      }
      if(val==-2) {
        this.items.at(index).get('ptax').setValue(0);
      }
    })
    this.calculate(val, index);
  }

  get items() {
    return this.purchaseForm.get('items') as FormArray
  }

  calculate(val, index) {
    if(this.items.length>0) {
      if(isNaN(this.items.at(index).get('pamount').value)) {
        this.items.at(index).get('pamount').setValue(0);
      }
      if(isNaN(this.items.at(index).get('pqty').value)) {
        this.items.at(index).get('pqty').setValue(1);
      }
      if(isNaN(this.items.at(index).get('pdiscount').value)) {
        this.items.at(index).get('pdiscount').setValue(0);
      }
      if(isNaN(this.items.at(index).get('ptax').value)) {
        this.items.at(index).get('ptax').setValue(0);
      }
      if(isNaN(this.items.at(index).get('ptotal').value)) {
        this.items.at(index).get('ptotal').setValue(0);
      }
      //const mytotal= +(this.items.at(index).get('pqty').value * (+this.items.at(index).get('pamount').value + +(this.items.at(index).get('ptax').value*0.01))) - +this.items.at(index).get('pdiscount').value;

      const mytotal= +(this.items.at(index).get('pqty').value * (+this.items.at(index).get('pamount').value + +(this.items.at(index).get('pamount').value * (this.items.at(index).get('ptax').value/100))));
      console.log(mytotal);
      this.items.at(index).get('ptotal').setValue(mytotal);
      this.items.at(index).get('ptotal').setValue((mytotal - ((mytotal * this.items.at(index).get('pdiscount').value)/100)).toFixed(3));
      this.calclulateTotal();
    } else {
      this.subtotal = 0;
      this.total=+this.subtotal + -this.discount + +this.tax + +this.delivery;
      this.total = this.total;
    }
  }

  calcamountfirst(val, index) {
    //val=this.items.at(index).get('currency').value;
    if(val!='' && val!=undefined) {
      var landingc=this.items.at(index).get('supplierprice').value * this.items.at(index).get('landing').value;
      var marginc=(100 - +parseFloat(this.items.at(index).get('margin').value))/100;
      this.items.at(index).get('pamount').setValue((landingc/marginc).toFixed(3));
      this.calculate(val, index);
      //this.calcamount(this.items.at(index).get('currency').value, index);
    }
  }

  calcamount(val, index) {
    val=this.items.at(index).get('currency').value;
    if(this.items.at(index).get('supplierprice').value!='' && this.items.at(index).get('supplierprice').value!=undefined) {
      var landingc=this.items.at(index).get('supplierprice').value * val.split('##')[0];
      var marginc=(100 - +parseFloat(val.split('##')[1]))/100;
      this.items.at(index).get('landing').setValue(val.split('##')[0]);
      this.items.at(index).get('margin').setValue(val.split('##')[1]);
      this.items.at(index).get('pamount').setValue((landingc/marginc).toFixed(3));
      this.calculate(val, index);
    }
  }

  calclulateTotal() {
    console.log("i am called");
    const sumArray = [];
    this.tax=0;
    for (const control of this.items.controls) {

      sumArray.push(control.value['ptotal']);
      console.log(control.value['ptax']);
      if(control.value['ptax']>0) {
        console.log(control.value['ptax']);
        this.tax=+this.tax + +(control.value['pqty'] * (control.value['pamount'] * (control.value['ptax']/100)));
      }
    }

    this.tax=this.tax.toFixed(3);
    console.log(this.getTotalAmount(sumArray));

    this.subtotal = this.getTotalAmount(sumArray).toFixed(3);
    //this.total=+this.subtotal + -this.discount + +this.tax + +this.delivery;
    this.total=+this.subtotal + -this.discount  + +this.delivery;
    this.total = this.total.toFixed(3);
  }

  getTotalAmount = (arr) => {
    return arr.reduce((a, b) => +a + +b, 0);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
      this.getInvoiceNo();
    }
  }

  selectcustomer(val) {
    this.customer=val;
    this.customers.forEach((customer) => {
      if (val == customer.id) {
        //this.customercurrency=customer.currency;
      }
    })
  }

  getpurchase() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('quotations', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  getInvoiceNo() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('new-invoice', this.itemData).then((res: any) => {
          this.invoice=('0' + res.invoice).slice(-2);
          this.invyear=res.invyear;
          this.invmonth=res.invmonth;
      });
  }

  changeDate(val:any) {
    this.ndate=1;
    this.newdata=val;
  }

  async addpurchase() {
      this.einvoice=0;
      this.ecustomer=0;
      this.ebuyerref=0;
      this.etdate=0;
      this.evalidity=0;
      this.epaymentterms=0;
      this.edeliveryterms=0;
      this.eitems=0;
      this.vcount=0;
      if(this.invoice=='') {
        this.einvoice=1;
        this.vcount=1;
      }
      if(this.customer=='' || this.customer==undefined) {
        this.ecustomer=1;
        this.vcount=1;
      }
      if(this.buyerref=='' || this.buyerref==undefined) {
        this.ebuyerref=1;
        this.vcount=1;
      }
      if(this.tdate=='' || this.tdate==undefined) {
        this.etdate=1;
        this.vcount=1;
      }
      if(this.validity=='' || this.validity==undefined) {
        this.evalidity=1;
        this.vcount=1;
      }
      if(this.paymentterms=='' || this.paymentterms==undefined) {
        this.epaymentterms=1;
        this.vcount=1;
      }
      if(this.deliveryterms=='' || this.deliveryterms==undefined) {
        this.edeliveryterms=1;
        this.vcount=1;
      }
      if (!this.purchaseForm.valid) {
        this.eitems=1;
        this.vcount=1;
      }
      if(this.items.length<1) {
        this.eitems=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.session.myLoader(1);
          console.log(this.tdate);

          var date = new Date(this.tdate)
          var userTimezoneOffset = date.getTimezoneOffset() * 60000;


          this.itemData = {
            "id":this.cid,
            "invoiceno":this.invoice,
            "quoteno":'4001'+this.invyear+this.invmonth+this.invoice,
            "customer":this.customer,
            "name":'',
            "address":'',
            "phone":'',
            "totalitems":5,
            "subtotal":this.subtotal,
            "tax":this.tax,
            "discount":this.discount,
            "delivery":this.delivery,
            "total":this.total,
            "remarks":this.remarks,
            //tdate":this.tdate.replace('T', ' ').replace('Z', ''),
            "tdate":new Date(date.getTime() - userTimezoneOffset),
            "currency":this.customercurrency,
            "buyerref":this.buyerref,
            "validity":this.validity,
            "paymentterms":this.paymentterms,
            "deliveryterms":this.deliveryterms,
            "items": this.purchaseForm.value,
            "user":this.userid,
            "quoteid":this.quoteid,
            "ndate":this.ndate
          }
          await this.apiService.post('edit-quotation', this.itemData).then((res: any) => {
            this.btntxt="Editing Quotation..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Edit Quotation';
                this.btndisabled=0;
                this.session.myLoader(0);
                //this.getQuotation();
                this.router.navigate(['/quotation-view/', { id: this.quoteid }]);
              }
          });
      }
  }

  view(qid) {
    //this.router.navigate(['/quotation-view/', { queryParams: { id: qid }}]);
    this.router.navigate(['/quotation-view/', { id: qid }]);
  }

  edit(qid) {
    //this.router.navigate(['/quotation-view/', { queryParams: { id: qid }}]);
    this.router.navigate(['/quotation-edit/', { id: qid }]);
  }


}
