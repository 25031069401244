import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {DatePipe} from '@angular/common';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  contactTab: boolean;
  chatTab: boolean = true;

  
  email:any='';
  mobile:any='';
  city:any='';
  country:any='';
  tin:any = '';
  btntxt:any = 'Add Quotation';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  invoice:any='';
  buyerref:any = '';
  validity:any = '';
  paymentterms:any = '';
  deliveryterms:any = '';
  customer:any='';
  tdate:any ='';

  //validation variables
  vcount:any = 0;
  einvoice:any = 0;
  ebuyerref:any = 0;
  etdate:any = 0;
  evalidity:any = 0;
  epaymentterms:any = 0;
  edeliveryterms:any = 0;
  ecustomer:any = 0;

  cid:any;
  customers:any;
  productlist:any;

  product:any;
  pamount:any;
  pqty:any;
  ptax:any;
  pdiscount:any;
  ptotal:any;

  subtotal:any = 0;
  discount:any = 0;
  tax:any = 0;
  total:any = 0;
  delivery:any = 0;
  vat:any;

  remarks:any = '';

  customercurrency:any = '';

  purchaseForm: FormGroup;
  currencylist:any;
  userid:any;
  eitems:any = 0;
  //items: FormArray;
  invmonth:any;
  invyear:any;
  search:any;
  myrole:any;

  certificates:any = '';

  title:any  = 'Quotation Excel Sheet';


  constructor(private datePipe: DatePipe, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute,) { 
    this.cid = sessionStorage.getItem("cid");    
    this.userid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    this.getpurchase();
    this.getcustomers();
    this.getproducts();
    this.getConversion();
    this.getSettings();
    this.getCertificates();

    this.tdate = new Date();
    this.tdate = this.datePipe.transform(this.tdate,"yyyy-MM-dd");
  }

  

  getSettings() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('settings', this.itemData).then((res: any) => {
      this.vat=res.data[0].vat;
      this.validity=res.data[0].validity;
      this.paymentterms=res.data[0].paymentterms;
      this.deliveryterms=res.data[0].deliveryterms;
      this.customercurrency=res.data[0].currencysymbol;
    });
  }

  getCertificates() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('get-certificates', this.itemData).then((res: any) => {
      this.certificates=res.data;
    });
  }

  getsearch(searchval) {
    this.itemData = {
      "id":this.cid,
      search:searchval
    }
    this.apiService.post('search-quotations', this.itemData).then((res: any) => {
        this.catlist=res.data;                          
    });
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.currencylist=res.data;
    });
  }

  getcustomers() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('customers', this.itemData).then((res: any) => {
      this.customers=res.data;
    });
  }

  getproducts() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('products', this.itemData).then((res: any) => {
          this.productlist=res.data;                          
      });
  }

  ngOnInit(): void {
      this.purchaseForm = this.formBuilder.group({
        items: this.formBuilder.array([ this.createItem() ])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
        product: ['', [Validators.required]],
        prodesc:'',
        certificate: '',
        ddate:'',
        supplierprice: '',
        currency: '',
        landing: '',
        margin: '',
        pamount: ['0', [Validators.required]],
        pqty: ['1', [Validators.required]],
        ptax: '0',
        taxamount: '0',
        discountamount: '0',
        pdiscount: '0',
        ptotal: ['', [Validators.required]],
    });
  }

  addItem(): void {
    //this.items = this.purchaseForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    this.items.removeAt(index);
    this.calculate(0, index);
  }

  getRates(val,index) {
    let selectedProducts;
    this.productlist.forEach((product) => {
      if (val == product.id) {
        selectedProducts = product;
        this.items.at(index).get('supplierprice').setValue(product.price);
        this.items.at(index).get('pamount').setValue(product.price);
        this.items.at(index).get('pqty').setValue('1');
        this.items.at(index).get('pdiscount').setValue('0');
        this.items.at(index).get('ptax').setValue(product.vat);
        this.items.at(index).get('ptotal').setValue(product.price);
        this.subtotal=product.price;
      }
      if(val==-1) {        
        this.items.at(index).get('ptax').setValue(5);
      }
      if(val==-2) {        
        this.items.at(index).get('ptax').setValue(0);
      }
    })
    this.calculate(val, index);
  }

  get items() {
    return this.purchaseForm.get('items') as FormArray
  }

  calculate(val, index) {
    if(this.items.length>0) {
      if(isNaN(this.items.at(index).get('pamount').value)) {
        this.items.at(index).get('pamount').setValue(0);
      }
      if(isNaN(this.items.at(index).get('pqty').value)) {
        this.items.at(index).get('pqty').setValue(1);
      }
      if(isNaN(this.items.at(index).get('pdiscount').value)) {
        this.items.at(index).get('pdiscount').setValue(0);
      }
      if(isNaN(this.items.at(index).get('ptax').value)) {
        this.items.at(index).get('ptax').setValue(0);
      }
      if(isNaN(this.items.at(index).get('ptotal').value)) {
        this.items.at(index).get('ptotal').setValue(0);
      }
      const mytotal= +(this.items.at(index).get('pqty').value * (+this.items.at(index).get('pamount').value + +(this.items.at(index).get('pamount').value * (this.items.at(index).get('ptax').value/100))));
      //const newdiscount = (mytotal * this.items.at(index).get('pdiscount').value)/100;
      this.items.at(index).get('taxamount').setValue(+(this.items.at(index).get('pqty').value * (this.items.at(index).get('pamount').value * (this.items.at(index).get('ptax').value/100))));
        

      const newmy = mytotal - ((mytotal * this.items.at(index).get('pdiscount').value)/100);
      const newmy2= +newmy + (newmy * (this.items.at(index).get('ptax').value/100));

      
      this.items.at(index).get('ptotal').setValue((mytotal - ((mytotal * this.items.at(index).get('pdiscount').value)/100)).toFixed(2));
      //this.items.at(index).get('ptotal').setValue(newmy2);
      this.calclulateTotal();
    } else {
      this.subtotal = 0;
      this.total=+this.subtotal + -this.discount + +this.tax + +this.delivery;
      this.total = this.total;
    }
  }

  calcamountfirst(val, index) {
    //val=this.items.at(index).get('currency').value;
    var landingc=this.items.at(index).get('supplierprice').value * this.items.at(index).get('landing').value;
    var marginc=(100 - +parseFloat(this.items.at(index).get('margin').value))/100;
    this.items.at(index).get('pamount').setValue((landingc/marginc).toFixed(2));
    this.calculate(val, index);
  }

  calcamount(val, index) {
    val=this.items.at(index).get('currency').value;
    var landingc=this.items.at(index).get('supplierprice').value * val.split('##')[0];
    var marginc=(100 - +parseFloat(val.split('##')[1]))/100;
    this.items.at(index).get('landing').setValue(val.split('##')[0]);
    this.items.at(index).get('margin').setValue(val.split('##')[1]);
    this.items.at(index).get('pamount').setValue((landingc/marginc).toFixed(2));
    this.calculate(val, index);
  }

  calclulateTotal() {
    const sumArray = [];
    this.subtotal=0;
    this.tax=0;
    for (const control of this.items.controls) {
      sumArray.push(control.value['ptotal'])
      console.log(control.value['ptotal']);
      this.subtotal=+this.subtotal + +control.value['ptotal'];
      if(control.value['ptax']>0) {
        this.tax=+(control.value['pqty'] * (control.value['pamount'] * (control.value['ptax']/100)));
        this.tax=this.tax.toFixed(2);
      }


    }

    //this.subtotal = this.getTotalAmount(sumArray);
    this.total=+this.subtotal.toFixed(2) + -this.discount + +this.delivery;
    this.total = this.total;
  }

  getTotalAmount = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
      this.getInvoiceNo();
    }
  }

  selectcustomer(val) {
    this.customer=val;
    this.customers.forEach((customer) => {
      if (val == customer.id) {
        //this.customercurrency=customer.currency;
      }
    })
  }

  getpurchase() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('quotations', this.itemData).then((res: any) => {
          this.catlist=res.data;                          
      });
  }

  getInvoiceNo() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('new-invoice', this.itemData).then((res: any) => {
          this.invoice=('0' + res.invoice).slice(-2);    
          this.invyear=res.invyear;
          this.invmonth=res.invmonth;                      
      });
  }

  addpurchase() {
      this.einvoice=0;
      this.ecustomer=0;
      this.ebuyerref=0;
      this.etdate=0;
      this.evalidity=0;
      this.epaymentterms=0;
      this.edeliveryterms=0;
      this.eitems=0;
      this.vcount=0;
      if(this.invoice=='') {
        this.einvoice=1;
        this.vcount=1;
      }
      if(this.customer=='' || this.customer==undefined) {
        this.ecustomer=1;
        this.vcount=1;
      }
      if(this.buyerref=='' || this.buyerref==undefined) {
        this.ebuyerref=1;
        this.vcount=1;
      }
      if(this.tdate=='' || this.tdate==undefined) {
        this.etdate=1;
        this.vcount=1;
      }
      if(this.validity=='' || this.validity==undefined) {
        this.evalidity=1;
        this.vcount=1;
      }
      if(this.paymentterms=='' || this.paymentterms==undefined) {
        this.epaymentterms=1;
        this.vcount=1;
      }
      if(this.deliveryterms=='' || this.deliveryterms==undefined) {
        this.edeliveryterms=1;
        this.vcount=1;
      }
      if (!this.purchaseForm.valid) {
        this.eitems=1;
        this.vcount=1;
      }
      if(this.items.length<1) {
        this.eitems=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.cid,
            "invoiceno":this.invoice,
            "quoteno":'4001'+this.invyear+this.invmonth+this.invoice,
            "customer":this.customer,
            "name":'',
            "address":'',
            "phone":'',
            "totalitems":5,
            "subtotal":this.subtotal,
            "tax":this.tax,
            "discount":this.discount,
            "delivery":this.delivery,
            "total":this.total,
            "remarks":this.remarks,
            "tdate":this.tdate,
            "currency":this.customercurrency,
            "buyerref":this.buyerref,
            "validity":this.validity,
            "paymentterms":this.paymentterms,
            "deliveryterms":this.deliveryterms,
            "items": this.purchaseForm.value,
            "user":this.userid
          }
          this.apiService.post('quotation', this.itemData).then((res: any) => {
            this.btntxt="Adding Quotation..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Quotation';
                this.btndisabled=0;
                this.catlist=res.data; 
                this.onTab(1);
              }                          
          });
      }
  }

  view(qid) {
    //this.router.navigate(['/quotation-view/', { queryParams: { id: qid }}]);
    this.router.navigate(['/quotation-view/', { id: qid }]);
  }

  edit(qid) {
    //this.router.navigate(['/quotation-view/', { queryParams: { id: qid }}]);
    this.router.navigate(['/quotation-edit/', { id: qid }]);
  }

  delete(qid) {
    if(confirm("Are you sure you want to delete this quotation?")) {
      this.itemData = {
        "id":qid,
        "cid":this.cid,
      }
      this.apiService.post('delete-quotation', this.itemData).then((res: any) => {
          this.getpurchase();                     
      });
    }
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }

}
