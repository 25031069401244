import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  cid:any;

  unitname:any='';
  unitalias:any='';
  countryid:any = '';
  currencyid:any = '';
  unitaddress:any = '';
  userid:any = '';

  btntxt:any = 'Add Unit';
  savetxt:any = 'Save';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  units:any = '';
  countries:any;
  currencies:any = '';

  vcount:any = 0;
  eunitname:any = '';
  eunitalias:any = '';
  ecountryid:any = '';
  ecurrencyid:any = '';
  eunitaddress:any = '';

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {

    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getCountry();
    this.getCurrency();
    this.getUnits();
  }

  ngOnInit(): void {
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getUnits() {
      this.itemData = {

      }
      this.apiService.post('units', this.itemData).then((res: any) => {
          this.units=res.data;
      });
  }

  getCountry() {
      this.itemData = {

      }
      this.apiService.post('countries', this.itemData).then((res: any) => {
          this.countries=res.data;
      });
  }

  getCurrency() {
      this.itemData = {

      }
      this.apiService.post('currencies', this.itemData).then((res: any) => {
          this.currencies=res.data;
      });
  }

  upload() {
    this.vcount=0;
    this.eunitname='';
    this.eunitalias='';
    this.ecountryid='';
    this.ecurrencyid='';

    if(this.unitname=='' || this.unitname==undefined) {
      this.vcount=1;
      this.eunitname='Please enter Unit Name';
    }
    if(this.unitname.length>100) {
      this.vcount=1;
      this.eunitname='Max characters allowed is 100';
    }
    if(this.unitalias=='' || this.unitalias==undefined) {
      this.vcount=1;
      this.eunitalias='Please enter Unit Alias';
    }
    if(this.unitalias.length>100) {
      this.vcount=1;
      this.eunitalias='Max characters allowed is 100';
    }
    if(this.countryid=='' || this.countryid==undefined) {
      this.vcount=1;
      this.ecountryid='Please select Unit Country';
    }
    if(this.currencyid=='' || this.currencyid==undefined) {
      this.vcount=1;
      this.ecurrencyid='Please select Unit Currency';
    }
    if(this.unitaddress.length>200) {
      this.vcount=1;
      this.eunitaddress='Max characters allowed is 200';
    }
    if(this.vcount==0) {
      this.itemData = {
        "unitname":this.unitname,
        "unitalias":this.unitalias,
        "countryid":this.countryid,
        "currencyid":this.currencyid,
        "unitaddress":this.unitaddress,
      }
      this.apiService.post('unit', this.itemData).then((res: any) => {
          this.btntxt="Saving Unit..."
          this.btndisabled=1;
          if(res.status==200) {
            this.unitname='';
            this.unitalias='';
            this.btndisabled=0;
            this.getUnits();
            this.btntxt="Add Unit";
            this.onTab(1);
          }
      });
    }
  }

}
