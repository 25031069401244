import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-memberpayments',
  templateUrl: './memberpayments.component.html',
  styleUrls: ['./memberpayments.component.scss']
})
export class MemberpaymentsComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab: boolean;

  name:any='';
  joindate:any = '';
  ujoindate:any = '';
  uname:any='';
  address:any='';
  uaddress:any='';
  email:any='';
  uemail:any='';
  mobile:any='';
  umobile:any='';
  role:any='';
  urole:any='';
  password:any='';
  btntxt:any = 'Add Payment';
  ubtntxt:any = 'Update Payment Details'
  itemData:any='';
  btndisabled:any = 0;
  message:any='';
  catlist:any='';
  amount:any = '';
  tdate:any = '';
  remark:any = '';

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  emember:any = 0;
  edate:any = 0;
  eemail:any = 0;
  euname:any = 0;

  uvcount:any = 0;
  uename:any = 0;
  uerole:any = 0;
  ueemail:any = 0;

  cid:any;
  conversionlist:any;
  staffdet:any;
  currentstaff:any = '';
  myuserid:any;
  myrole:any;
  search:any = '';

  accountno:any = '';
  accountname:any = '';
  bank:any = '';
  branch:any = '';
  ifsc:any = '';
  basicsalary:any = '';
  ctc:any = '';
  memlist:any = '';
  memid:any = '';

  constructor(private apiService: ApiService,private router: Router,private activatedRoute: ActivatedRoute) {
    this.cid = sessionStorage.getItem("cid");
    this.myuserid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    if(this.myrole>1) {
      this.router.navigate(['/dashboard']);
    }

    this.myrole = sessionStorage.getItem("role");
    this.getvendor();
    this.getConversion();
    this.getMembers();
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  ngOnInit(): void {
  }

  searchstaff(val:any) {
    this.itemData = {
      "id":this.cid,
      "search":val
    }
    this.apiService.post('members', this.itemData).then((res: any) => {
        this.catlist=res.data;
    });
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }else if (number == '3') {
      this.editTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
  }

  getMembers() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('members', this.itemData).then((res: any) => {
          this.memlist=res.data;
      });
  }


  getvendor() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('member-payments', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  getStaffDetail(id) {
    this.currentstaff=id;
    this.onTab(3);
      this.itemData = {
        "id":this.cid,
        "userid":id

      }
      this.apiService.post('member-detail', this.itemData).then((res: any) => {
          this.staffdet=res.data;
          this.uname=res.data[0].name;
          this.uaddress=res.data[0].address;
          this.umobile=res.data[0].phone;
          this.uemail=res.data[0].email;
          this.ujoindate=res.data[0].joined;
      });
  }

  addvendor() {
      this.ename=0;
      this.emember=0;
      this.edate=0;
      this.vcount=0;
      if(this.memid=='') {
        this.emember=1;
        this.vcount=1;
      }
      console.log(this.amount);
      if(this.amount=='' || this.amount==0) {
        this.ename=1;
        this.vcount=1;
      }
      if(this.tdate=='') {
        this.edate=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.memid,
            "amount":this.amount,
            "tdate":this.tdate,
            "remark":this.remark,
          }
          this.apiService.post('member-payment', this.itemData).then((res: any) => {
            this.btntxt="Adding Payment..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Payment';
                this.btndisabled=0;
                this.amount='';
                this.tdate='';
                this.remark='';
                this.memid='';
                this.onTab(1);
                this.getvendor();
              }
          });
      }
  }

  removeStaff(id) {
    if(confirm("Are you sure you want to delete this Payment?")) {
        this.itemData = {
          "id":this.cid,
          "userid":id
        }
        this.apiService.post('remove-member-payment', this.itemData).then((res: any) => {
              this.getvendor();
        });
    }
  }


}
