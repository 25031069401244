import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-projectview',
  templateUrl: './projectview.component.html',
  styleUrls: ['./projectview.component.scss']
})
export class ProjectviewComponent implements OnInit {

  cid:any;

  @ViewChild("myEditor", { static: false }) myEditor: any; 

  Summary: boolean = true;
  Modules: boolean;
  Tasks: boolean;
  Testing: boolean;
  
  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = '';
  cost:any = '';

  btntxt:any = 'Add Project';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  clientdata:any;
  statusdata:any;
  projectdata:any;
  pid:any;
  about:any;
  notes:any;
  notemessage:any;
  notetxt:any = 'Add Notes';
  data:any = '';

  public Editor = ClassicEditor;

  statuslist:any;



  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute) { 
    this.pid = this.activatedRoute.snapshot.queryParamMap.get('id');
    console.log(this.pid);
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getStatus();
    this.getProject();
  }

  ngOnInit(): void {
  }

  addnote() {
    this.itemData = {
      "id":this.cid,
      "project":this.pid,
      "note":this.myEditor.data,
      "userid":this.userid
    }
    this.apiService.post('note', this.itemData).then((res: any) => {   
        this.notes = res.data;  
        this.data='';     
    });
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  onTab(number) {

    this.Summary = false;
    this.Modules = false;
    this.Tasks = false;
    this.Testing = false;

    if (number == '1') {
      this.Summary = true;
    }
    else if (number == '2') {
      this.Modules = true;
    }
    else if (number == '3') {
      this.Tasks = true;
    }
    else if (number == '4') {
      this.Testing = true;
    }
  }

  getProject() {
      this.itemData = {
        "id":this.cid,
        "project":this.pid
      }
      this.apiService.post('project-view', this.itemData).then((res: any) => {
          this.projectdata=res.data;   
          this.about=res.data[0].description;
          this.clientdata = res.client;      
          this.notes = res.notes;        
      });
  }

  getStatus() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('allstatus', this.itemData).then((res: any) => {
          this.statuslist=res.data;                    
      });
  }

  upload() {
      this.itemData = {
        "id":this.cid,
        "client":this.client,
        "user":this.userid,
        "forclient":this.forclient,
        "name":this.name,
        "description":this.description,
        "start_date":this.start_date,
        "end_date":this.end_date,
        "status":this.status,
        "progress":this.progress,
        "cost":this.cost
      }
      this.apiService.post('project', this.itemData).then((res: any) => {
          this.btntxt="Saving Project..."
          this.btndisabled=1;
          if(res.status==200) {  
            this.forclient='';
            this.name='';           
            this.description=''; 
            this.start_date=''; 
            this.end_date=''; 
            this.progress=''; 
            this.status='';  
            this.cost='';
            this.btndisabled=0;
            this.btntxt="Add Project";
            this.onTab(1);
          }                          
      });
  }
}
